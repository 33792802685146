.pageTitle {
  background: url(../images/common/page_title_line.png) bottom no-repeat;
  text-align: center;
  width: 90%;
  margin: 0 auto;
  padding-bottom: 25px;
  width: 90%;
  margin: 0 auto;
  @media (--desktop) {
    width: auto;
    padding-bottom: 40px;
  }
  &_content {
    width: 50%;
    margin: 0 auto;
    @media (--desktop) {
      width: auto;
    }
  }
}