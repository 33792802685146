.swiper-container {
  /* width: 120vw; */
  margin: 0 auto;
  position: relative;
  background: none;
  left: 0;
  top: 10px;
  overflow: hidden;
  width: 95%;
  @media (--tabletOnly) {
    left: 0px;
    top: 30px;
    width: 90%;
  }
  @media (--desktop) {
    width: 900px;
    top: -10px;
    left: 20px;
  }
  .swiper-wrapper {
    /* width: 100vw;
    height: 100vh; */
  }
}

.swiperframeWrap {
  width: 100%;
  top: -11%;
  z-index: 100;
  position: absolute;
  width: 100%;
  height: 81vw;
  background: url("../images/top/slide_frame_sp.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  @media (--desktop) {
    background: none;
    height: auto;
    background: url("../images/top/slide_frame.png");
  }
  & > p {
    width: 135%;
    margin: 0 auto;
    text-align: center;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    display: none;
    @media (--desktop) {
      width: 1010px;
      display: block;
    }
    > img {
      width: 100%;
    }
  }
}
.swiperFrame {
  background: url(../images/top/frame_02.png);
  background-repeat: no-repeat;
  background-size: contain;
  max-width: 1100px;
  height: 100%;
  width: 150%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
 
  @media (--desktop) {
    width: 1100px;
    top: 530px;
    height: 700px;
  }
}

.swiperArrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  &.next {
    right: -30px;
  }
  &.prev {
    left: -30px;
  }
}