.companyPage {
  .pageSubTitle {
    text-align: center;
    margin: 30px auto;
    width: 60%;
    @media (--desktop) {
      width: auto;
      margin: 90px 0;
    }
  }
  .companyTable {
    width: 90%;
    margin: 0 auto;
    @media (--desktop) {
      width: 900px;
    }

    tr {
      border-bottom: none;
      display: block;
      width: 100%;
      @media (--desktop) {
        padding: 30px 0;
      }
      + tr {
        margin-top: 30px;
        @media (--desktop) {
          margin-top: 0;
        }
      }
      @media (--desktop) {
        border-bottom: 2px dotted #9e9e9e;
      }
      th,td {
        text-align: left;
        width: 100%;
        color: #525151;
        display: block;
        @media (--desktop) {
          width: auto;
          display: table-cell;
        }
      }
      th {
        font-weight: bold;
        text-align: left;
        border-bottom: 2px dotted #9e9e9e;
        padding-bottom: 3%;
        margin-bottom: 4%;
        @media (--desktop) {
          width: 300px;
          padding: 30px;
          border: none;
          margin: 0;
          padding: 0;
          text-align: right;
          padding-right: 70px;
        }
      }
      td {
        .name_en {
          display: block;
          @media (--desktop) {
            display: inline;
          }
        }
      }
    }
  }
  @media (max-width: 600px) {
    table {
      width: 100%;
    }
    th,td {
      display: block;
      text-align: left;
      width: 100%;
    }
  }
}

