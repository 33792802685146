.header {
	width: 100%;
	padding: 20px;
  background:url("../images/common/header_bg.png");
  background-repeat: repeat-x;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000000;
  height: 100px;
  background-size: cover;
  @media (--desktop) {
    background-size: cover;
    height: auto;
    padding: 30px;
  }
  @media (--wideDesktop) {
    background-size: contain;
  }
  &_items {
    margin: 0 auto;
    width: 180px;
    @media (--desktop) {
      width: 800px;
      display: flex;
      /* align-items: center; */
      justify-content: space-between;
      margin: 0 auto 10px;
     }
     .headerLogoWrap {
       text-align: center;
      .headerLogo {
        width: 150px;
        margin: 0 auto;
        text-align: center;
        transition:opacity .3s;
          opacity: 1;
          &:hover {
            opacity: .7;
          }
        /* > a {
          display: block;
          width: 100%;
          text-align: center;
        } */
        @media (--tabletOnly) {
          text-emphasis: center;
        }
        @media (--desktop) {
          width: auto;
          margin: auto;
        }
      }
     }
    .headerHamber {
      display: block;
      cursor: pointer;
      position: absolute;
      z-index: 10;
      right: 10px;
      top: 10px;
      width: 60px;
      height: 60px;
      background:  url("../images/common/hamburger-open.png") center center / contain no-repeat;
      transition: all .3s;
      &.is-active {
        background: url("../images/common/hamburger-close.png") center center / contain no-repeat;
      }
      @media (--desktop) {
       display: none;
      }
    }
    .headerNav  {
      background: #fff;
      border: 4px solid #FCDF00;
      width: 100%;
      left: 0;
      top: 0;
      position: absolute;
      transform: translate3D(0, -100%, 0);
      transition: all .3s;
      opacity: 0;
      &.is-active {
        display: block;
        opacity: 1;
        transform: translate3D(0, 0%, 0);
      }
      @media (--desktop) {
        background:none;
        opacity: 1;
        position: static;
        transform: none;
        border: none;
        width: auto;
      }
    }
    .globalMenu {
      justify-content:space-between;
      /* transform: translate(0, -100%); */
      position: relative;
      padding: 90px 20px 20px 20px;
      /* transition: all .3s; */
      width: 100%;
      @media (--tabletOny) {
        padding: 90px 50px 50px;
      }
      @media (--desktop) {
        padding-top: 0;
      }
      &_officer {
        /* background:url("../images/common/line_green_sp.svg") 100% -3px no-repeat; */
        border-top: solid 4px #79dd01;
        @media (--desktop) {
          border: none;
        }
      }
      &_company {
        /* background:url("../images/common/line_red_sp.svg") 100% -3px no-repeat; */
        border-top: solid 4px #f70b3c;
        @media (--desktop) {
          border: none;
        }
      }
      &_news {
        /* background:url("../images/common/line_yellow_sp.svg") 100% -3px no-repeat; */
        border-top: solid 4px #fae20e;
        @media (--desktop) {
          border: none;
        }
      }
      &_contact {
        border-top: solid 4px #1df3fd;
        border-bottom: solid 4px #8700ff;
        @media (--desktop) {
          border: none;
        }
        /* background:url("../images/common/line_cyan_sp.svg") 100% -3px no-repeat,url("../images/common/line_purple_sp.svg") 100% 104% no-repeat; */
      }
      @media (--desktop) {
        display: flex;
        position: static;
        width: auto;
        margin-top: 0;
        opacity: 1;
        background: none;
        transform: none;
      }
      li {
        padding: 20px;
        @media (--tablet) {
          padding: 40px;
        }
        @media (--desktop) {
          padding: 0;
          background: url("../images/common/header_menu_line.png") no-repeat;
          background-repeat-x: no-repeat;
          &:last-child {
            @media (--desktop) {
              background: url("../images/common/header_menu_line.png") no-repeat, url("../images/common/header_menu_line.png") right no-repeat ;
              background-repeat-x: no-repeat;
            }
          }
        }
        & > a {
          display: block;
          width: 60%;
          transform: scale(1);
          transition: all .3s;
          @media (--desktop) {
            width: auto;
            padding: 0 20px;
            &:hover {
            transform: scale(1.1);
          }
          }
        }
      }
    }
  }
}
