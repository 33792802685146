/* 参考
http://scene-live.com/page.php?page=97
 */
 @custom-media --smartphoneMinHeight (max-height: 528px);
 @custom-media --smartphoneMiddleHeight (min-height: 550px);
 @custom-media --smartphoneMostHeight (min-height: 580px);
 @custom-media --smartphoneMaxHeight (max-height: 736px);
 @custom-media --smartphoneMinWidth (max-width: 320px);
 @custom-media --iPhoneX (min-width: 370px) and (min-height: 600px);
 @custom-media --smartphoneMiddleWidth (min-width: 360px);
 @custom-media --smartphone (max-width: 599px);
 @custom-media --tablet (min-width: 600px);
 @custom-media --tabletOnly (min-width: 600px) and (max-width: 959px);
 @custom-media --tabletMax (max-width: 959px);
 @custom-media --desktop (min-width: 960px);
 @custom-media --wideDesktop (min-width: 1281px);
 @custom-media --wideDesktopMinHeight (min-height: 1096px);
 @custom-media --iMac (min-width: 1700px);
 @custom-media --desktopHeight (max-height: 800px);
 