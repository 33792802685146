/*西久保くんのアニメーション*/

@keyframes umbrella {
  0% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(5deg);
  }
}

/* ページ共通で使用するポップアップ */
@keyframes popup {
  0% {
    transform: translate3d(30px, 0, 0)
  }
  100% {
    transform: translate3d(30px, 30px, 0)
  }
}

@keyframes popup_sp {
  0% {
    transform: translate3d(30px, 0, 0)
  }
  100% {
    transform: translate3d(30px, 10px, 0)
  }
}

/* 太陽のアニメーション */
@keyframes spin {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}

