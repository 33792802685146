.confirmPage {
  .confirm {
    margin-top: 50px;
    table {
        width: 90%;
        margin: 30px auto;
        @media (--desktop) {
          width: 100%;
          margin-top: 45px;
        }
        tr {
          display: block;
          color: #525151;
          @media (--tabletOnly) {
            font-size: 19px;
           }
          @media (--desktop) {
           display: table-row;
          }
          & + tr {
            margin-top: 30px;
            @media (--tabletOnly) {
              margin-bottom: 50px;
             }
          }
          th {
            font-weight: bold;
            display: block;
            margin-bottom: 10px;
            text-align: left;
            @media (--desktop) {
              width: 370px;
              text-align: right;
              padding: 30px 0 30px 0;
            }
            .required {
              color: #ff093a;
            }
          }
          td {
            @media (--desktop) {
              width: 44%;
            }
          }
        }
    }
    .contactBtn {
      &.confirm {
        .contactBtn_decoration {
          top: 40px;
          @media (--tabletMax) {
            position: absolute;
          }
          &.minimurachan {
            @media (--smartphone) {
              left: 4%;
              top: 9px;
            }
            @media (--tabletOnly) {
              top: 4px;
              left: 9%;
            }
          }
          &.miniadchan {
            @media (--smartphone) {
              top: 66%;
              left: 83%;
            }
            @media (--tabletOnly) {
              top: 66%;
              left: 86%;
            }
          }
        }
      }
      .entry_action {
        .contact_ReturnBtn {
          &:hover .minimurachan {
            transform: rotate(329deg)
          }
        }
      }
      .contact_ReturnBtn {
        &:hover + .minimurachan {
         left: -388px;
         transform: rotate(366deg);
        }
      }
      /* サーバー側のformのスタイルを適用する*/
      .confirmForm_submit {
        .miniadchan {
          @media (--smartphone) {
            top: 66%;
          }
          @media (--tabletMax) {
            top: 64%;
          }
          @media (--desktop) {
            top: -30px;
          }
        }
        @media (--desktop) {
          &:hover .miniadchan {
            left: 2px;
            transform: rotate(360deg);
          }
        }
      }
    }
  }
}