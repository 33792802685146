
* {
  box-sizing: border-box;
}
img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
  backface-visibility: hidden;
  &[src$=".svg"] {
    width: 100%;
  }
}

.container{
  width: 960px;
  margin: 0 auto;
}

html, body {
  font-family: "Meiryo", "Hiragino Kaku Gothic ProW3", sans-serif;
}