.directorsPage_detail {
  .director_detail {
    margin-top: 50px;
    @media (--tabletOnly) {
      width: 84%;
      margin: 50px auto;
    }
    @media (--desktop) {
      display: flex;
      position: relative;
    }
    &.nishikubo {
      .director_detail_question {
        /* width: 50%; */
        text-align: left;
        @media (--tabletMax) {
          position: relative;
          padding: 4% 2%;
          text-align: left;
          line-height: 1.6;
          height: 39vw;
          width: 49%;
          &_bg {
            width: auto;
            padding: 8px 0px 3px 0;
            @media (--desktop) {
              margin-top: 30px;
            }
          }
        }
        @media (--tabletOnly) {
          height: 33vw;
        }
        @media (--desktop) {
          position: absolute;
          padding: 40px 3%;
          width: 300px;
          height: 213px;
        }
        &.name {
          @media (--desktop) {
            top: 370px;
            left: 10px;
          }
          background: url("../images/directors/profile_ballon_red_employee.png") center / contain no-repeat;
        }
        &_text {
          text-align: left;
        }
        &_bg {
          width: auto;
        }
        .director_detailLists {
          position: static;
          transform: translate(0, 0);
          @media (--tabletOnly) {
            margin-top: 10px;
          }
        }
        &.oneWard {
          background: url("../images/directors/profile_ballon_pink_employee.png") center / contain no-repeat;
          @media (--desktop) {
            right: -10px;
            bottom: -100px;
          }
        }
        &.sports {
          @media (--desktop) {
            bottom: -180px;
            left: 310px;
          }
          background: url("../images/directors/profile_ballon_blue_employee.png") center / contain no-repeat;
        }
      }
      .director_detail_person {
        margin-top: 60px;
      }
      .director_detail_questions {
        @media (--tabletMax) {
          width: 96%;
          margin: 0 auto;
        }
      }
    }
    &_person {
      width: 40%;
      max-width: 200px;
      margin: 0 auto;
      text-align: center;
      @media (--desktop) {

      }
      /* position: relative; */
      /* top: 200px; */
      @media (--desktop) {
        width: 200px;
        margin-bottom: 80px;
        margin-top: 190px;
      }
      &_rank {
        margin-top: 20px;
      }
    }
   .director_detail_questions {
     display: flex;
     flex-wrap: wrap;
     @media (--desktop) {
       display: block;
     }
   }
    &_question {
      text-align: center;
      width: 50%;
      position: relative;
      padding: 20%;
      line-height: 1.6;
      @media (--desktop) {
        position: absolute;
        padding: 50px 5%;
        width: 310px;
      }
      .director_detailLists {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        /* width: 83%; */
        width: 80%;
        margin: 0 auto;
        @media (--desktop) {
          position: static;
          transform:translate(0, 0);
          top:0;
          left: 0;
        }
      }
      &.name {
        background: url("../images/directors/profile_ballon_red.png") center / contain no-repeat;
        @media (--desktop) {
          top: -10px;
          left: 120px;
        }
      }
      &.birthday {
        background: url("../images/directors/profile_ballon_orange.png") center / contain no-repeat;
        @media (--desktop) {
          top: 36px;
          right: 60px;
        }
      }
      &.favoritePhrase {
        background: url("../images/directors/profile_ballon_green.png") center / contain no-repeat;
        @media (--desktop) {
          left: 0px;
          top: 301px;
        }
      }
      &.favoriteFood {
        background: url("../images/directors/profile_ballon_purple.png") center / contain no-repeat;
        @media (--desktop) {
          right: 20px;
          top: 310px;
        }
      }
      &.skill {
        background: url("../images/directors/profile_ballon_blue.png") center / contain no-repeat;
        @media (--desktop) {
          left: 50px;
          bottom: -100px;
        }
      }
      &.oneWard {
        background: url("../images/directors/profile_ballon_pink.png") center / contain no-repeat;
        @media (--desktop) {
          right: 30px;
          bottom: -140px;
        }
      }
      &_bg {
        padding: 7px 0px;
        margin: auto;
        background: url("../images/directors/director_detail_line.png") 0 100% repeat no-repeat;
        @media (--tablet) {
          width: 50%;
        }
        @media (--desktop) {
          padding: 0 0 11px;
          width: 60%;
        }
        & > img {
          max-height: 27px;
          @media (--tabletOnly) {
            max-height: 33px;
          }
          @media (--desktop) {
            height: auto;
            max-height:auto;
          }
        }
      }
      &_text {
        margin-top: 3px;
        font-size: 12px;
        /* width: 38vw; */
        width: 100%;
        color: #525151;
        @media (--smartphoneMinWidth) {
          font-size: 10px;
          line-height: 1.4;
        }
        @media (--tabletOnly) {
          font-size: 15px;
          width: auto;
        }
        @media (--desktop) {
          text-align: center;
          font-size: 17px;
          width: auto;
        }
      }
    }
  }
}


/* Firefox */

.ua-firefox {
  .directorsPage_detail{
    .director_detail {
      &_questions {
        display: block;
      }
      &_question {
        display: inline-block;
        @media (--tabletMax) {
          width: 49%;
        }
      }
    }
  }
}