.newsPageDetail {
  .main {
    margin: -170px auto 0;
    padding-top: 320px;
    @media (--desktop) {
      width: 900px;
    }
  }
  .newsDetailTitle {
    border-bottom: 4px dotted #9e9e9e;
    margin: 40px 0 40px;
    @media (--desktop) {
      margin: 80px 0 70px;
    }
    &_date {
      display: flex;
      align-items: center;
      &:before {
        content:"";
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url("../images/news/news_title_icon.png") center left / contain no-repeat;
      }
      &_year {
        color: #fe2d50;
      }
      &_day {
        color: #8900fd;
      }
    }
    &_description {
      color: #525151;
      font-weight: bold;
      margin: 15px 0;
    }
    .snsArea {
      display: flex;
      flex-direction: row-reverse;
      position: relative;
      top: -10px;
      .fb-like {
        margin-left: 10px;
      }
    }
  }
  .newsDetailPost {
    color: #525151;
    line-height: 1.6;
    @media (--smartphone) {
      overflow-x: hidden;
    }
    li {
      list-style: none;
    }
    h3 {
      font-weight: bold;
    }
    img {
      width: 100%;
      cursor: pointer;
      @media (--tablet) {
        width: 400px;
      }
    }
  }
}