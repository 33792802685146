.pageTop {
    position: fixed;
    cursor: pointer;
    opacity: 0;
    transition: all .3s ease-in-out;
    transform: translateY(30px);
    &.is-active {
      opacity: 1;
      transform: translateY(0px);
    }
    @media (--tabletMax) {
      width: 70px;
      bottom: 3%;
      right: 5%;
    }
    @media (--desktop) {
      width: 100px;
      right: 90px;
      bottom: 30px;
    }
}