.sun {
  width: 60px;
  height: 60px;
  position: absolute;
  animation: spin 4.5s linear infinite;
  transform-origin: center;
  z-index: 1000;
  top: -33px;
  right: 30px;
  @media (--tablet) {
    top: 20px;
    right: 90px;
    width: 90px;
    height: 90px;
  }
  @media (--desktop) {
    width: 130px;
    height: 130px;
    top: 10px;
    right: -30px;
  }
}

