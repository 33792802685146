.aboutPage {
  .main {
    position: relative;
    &:after {
      display: block;
      content: "";
      background: url("../images/about/illust_bear.png") no-repeat;
      background-size: 100%;
      position: absolute;
      width: 40%;
      height: 22%;
      bottom: -22%;
      right: 5%;
      @media (--desktop) {
        width: 347px;
        height: 197px;
        bottom: -170px;
        right: 0px;
      }
    }
  }
  .pageSubTitle {
    text-align: center;
    margin: 30px auto;
    width: 70%;
    @media (--desktop) {
      width: auto;
      margin: 90px 0;
    }
  }
  .aboutDescription {
    width: 90%;
    margin: 60px auto;
    background-image: linear-gradient(rgba(138,196,255,.4) .1em,transparent .2em);
    background-size: 100% 1.9em;
    padding: 5px 0;
    line-height: 1.9;
    font-size: 15px;
    color: #525151;
    @media (--desktop) {
      margin-bottom: 60px;
      font-weight: bold;
      font-size: 20px;
      padding: 5px 20px;
      width: auto;
    }
    .textRed {
      color:red;
    }
    & > p {
      /* border-top: 4px dotted #8ac4ff; */
      line-height: 1.8;
      font-weight: bold;
      padding: 5px 10px;
      color: #525151;
      
      &:last-child {
        /* border-top: none; */
        /* border-bottom: 4px dotted #8ac4ff; */
      }

    }
  }
}

