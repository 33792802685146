.corporatePage {
  .main {
    .corporate {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      flex-wrap: wrap;
      @media (--desktop) {
        margin-top: 80px;
      }
      & > li {
        margin: 0 auto;
        width: 90%;
        transform:scale(1);
        padding: 20px;
        background: url("../images/about/about_corporate_bg_sp.png") 0 0 / contain no-repeat;
        @media (--tablet) {
          width: 43%;
          transition: all .3s;
          background: url("../images/about/about_corporate_bg.png") 0 0 / cover no-repeat;
          &:hover {
            transform:scale(1.14);
          }
        }
      }
    }
  }
}