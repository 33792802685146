.okamuraPopup {
  position: relative;
  width: 90%;
  margin: 50px auto 0 auto;
  @media (--desktop) {
    width: 100%;
    max-width: 1100px;
  }
  &_content {
    width: 120px;
    height: 120px;
    animation: popup_sp 1.5s ease-in-out infinite;
    animation-direction: alternate;
    position: relative;
    z-index: 10000;
    @media (--tablet) {
      width: 130px;
      height: 130px;
    }
    @media (--desktop) {
      position: relative;
      width: 180px;
      height: 180px;
      animation: popup 1.5s ease-in-out infinite;
      animation-direction: alternate;
    }
  }
}

.okamura_popup {
  display: none;
}



