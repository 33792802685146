.directorsPage{
  .main {
    .director {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 90%;
      margin: 40px auto 30px auto;
      @media (--desktop) {
        margin-top: 60px;
      }
      @media (--tabletOnly) {
        margin-top: 60px;
      }
      & > li {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 50px;
        @media (--tabletOnly) {
          width: 48%;
          margin-bottom: 30px;
        }
        @media (--desktop) {
          width: 43%;
          margin-bottom: 30px;
        }
        &:last-child {
          flex-direction: row-reverse;
        }
      }
      &_img {
        width: 60%;
        &.nishikubo {
          transform: rotate(-20deg);
          transform-origin: top;
          animation: umbrella 1.5s ease-in-out infinite;
          animation-direction: alternate;
        }
      }
      &_info {
        padding: 5vh 24vw;
        text-align: center;
        margin-left: 20px;
        background-size: contain;
        width: 28vw;
        height: 44vw;
        position: relative;
        transition: transform .3s ease-in-out;
        background-size: 100% auto;
        &:hover {
          @media (--desktop) {
            transform: scale(1.2);
          }
        }
        @media (--tabletOnly) {
          padding: 30%;
          width: 40%;
          height: 100%;
         }
        @media (--desktop) {
          width: 240px;
          height: 215px;
          padding: 49px 46px;
         }
        &_rank {
          width: 90%;
          margin: 0 auto;
          @media (--smartphoneMinWidth) {
            font-size: 12px;
          }
          @media (--desktop) {
            width: 70%;
          }
        }
        &_content {
          display: block;
          position: absolute;
          top: 53%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 70%;
        }
        /* background: url("../images/directors/member_ballon_red.png") 0 0 / cover no-repeat; */
        &.red {
          background: url("../images/directors/member_ballon_red.png") 0 center / contain no-repeat;
          .director_info_rank {
            width: 80px;
          }
        }
        &.cyan {
          background: url("../images/directors/member_ballon_cyan.png") 0 center / contain no-repeat;
        }
        &.orange {
          background: url("../images/directors/member_ballon_orange.png") 0 center / contain no-repeat;
        }
        &.green {
          background: url("../images/directors/member_ballon_green.png") 0 center / contain no-repeat;
        }
        &.purple {
          background: url("../images/directors/member_ballon_purple.png") 0 center / contain no-repeat;
        }
        &.blue {
          background-size: contain;
          margin-left: 0;
          background: url("../images/directors/member_ballon_blue.png") center / contain no-repeat;
          @media (--desktop) {
            width: 170px;
            height: 130px;
            padding: 24px 46px;
          }
        }
        > a {
          text-decoration: none;
          font-weight: bold;
        }
        &_name {
          margin: 4% 0;
          color: #525151;
          font-size: 14px;
          font-weight: bold;
          @media (--smartphoneMinWidth) {
            font-size: 12px;
          }
          @media (--desktop) {
            font-size: 18px;
            margin: 8px 0;
          }
        }
      }
    }
  }
  .okamuraPopup {
    @media (--desktop) {
      margin-top: 130px;
    }
  }
}



/* IE用のクラス*/

.ua-ie {
  .directorsPage{
    .main {
      .director_info {
        width: 290px;
      }
    }
  }
}