.topPage {
  .main {
    margin-top: 160px;
    position: relative;
    width: 100%;
    @media (--desktop) {
      overflow: visible;
      width: 900px;
      margin: 0 auto;
      margin: 220px auto 0 auto;
    }
    @media (--tabletOnly) {
      margin-top: 220px;
    }
    .topCharacter {
      position: absolute;
      z-index: 100000;
      width: 20%;
      @media (--desktop) {
        width: auto;
      }
      & > picture {
        display: block;
      }
      &.minimurachan {
        right: 2%;
        bottom: -50px;
        @media (--desktop) {
          right: -40px;
          bottom: -50px;
        }
      }
      &.chibiadchan {
        bottom: -50px;
        left: 5%;
        @media (--desktop) {
          left: -40px;
          bottom: -49px;
        }
      }
    }
    .companyCatchphrase {
      position: absolute;
      z-index: 100000;
      width: 38%;
      @media (--desktop) {
        width: auto;
      }
      &.first {
        bottom: 76%;
        right: 20px;
        @media (--desktop) {
          bottom: 110px;
          right: -40px;
        }
      }
      &.second {
        top: -19%;
        left: 0px;
        @media (--desktop) {
          top: 0;
          left: -80px;
        }
      }
    }
  }
  .cloudyArea {
    height: 350px;
    background: url(../images/common/bg_cloud.gif);
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: -1;
    background-repeat-y: no-repeat;
  }
  .sun {
    top: -63px;
    right: 50px;
    @media (--desktop) {
      width: 130px;
      height: 130px;
      top: -70px;
      right: -80px;
    }
  }
}