.newsPage {
  .main {
    @media (--tabletMax) {
      width: 90%;
    }
  }
  .newsList {
    margin: 30px auto 0;
    & > li {
      border-bottom: 2px dotted #9e9e9e;
      display: flex;
      line-height: 1.6;
      flex-wrap: wrap;
      padding: 20px 0;
      @media (--desktop) {
        padding: 30px;
      }
      & > a {
        text-decoration: none;
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &_date {
      display: flex;
      align-items: center;
      &:before {
        content:"";
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url("../images/news/news_title_icon.png") center left / contain no-repeat;
      }
      &_year {
        color: #fe2d50;
      }
      &_day {
        color: #0681ff;
      }
    }
    &_description {
      color: #525151;
      font-weight: bold;
      /* display: flex;
      align-items: center; */
      position: relative;
      margin-top: 10px;
      @media (--tablet) {
        margin-left: 30px;
        margin-top: 0;
      }
    }
    &_decoration {
      @media (--smartphone) {
        position: relative;
      }
      &:after {
        content: "";
        width: 40px;
        height: 25px;
        /* height: 20px; */
        display: inline-block;
        background: url(../images/common/link_icon.png) right 0 / contain no-repeat;
        left: 10px;
        position: absolute;
        bottom: 0;
        transform: translateY(-50%);
        top: 50%;
        @media (--tablet) {
          position: absolute;
          display: inline-block;
          transform: translateY(-50%);
          top: 50%;
          left: 104%;
          margin-left: 10px;
        }
      }
    }
  }
}