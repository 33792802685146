.loading_img {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  display: flex;
  align-items: center;
  opacity: 0;
  justify-content: center;
  transition: all .4s;
  z-index: -1;
  &.is-active {
    opacity: 1;
    z-index: 1111111;
  }
}