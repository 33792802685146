.footer {
  bottom: 0;
  margin-top: 10px;
  width: 100%;
  padding: 50px 30px 20px;
  text-align: center;
  background: url("../images/common/footer_decoration.gif") repeat no-repeat;
  /* animation: loop 100s linear infinite; */
  background-size: contain;
  background-position: 0 0;
  overflow: hidden;
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  @media (--desktop) {
    text-align: initial;
    display: flex;
    padding: 120px 30px 30px;
    background-size: 50%;
    justify-content: space-around;
    flex-direction: row;
  }
  @media (--wideDesktop) {
    background-size: 45%;
  }
  &_cpright {
    position: relative;
    display: block;
    margin-top: 20px;
    width: 140px;
    @media (--desktop) {
      margin-top: 30px;
      width: 150px;
    }
  }
  &_shareArea {
    display: flex;
    position: relative;
    justify-content: center;
    @media (--desktop) {
      /* top: 90px; */
    }
    @media (--tabletOnly) {
      margin-top: 50px;
    }
    & > li {
      &:hover > a{
        background-position: bottom left;
      }
      & + li {
        margin-left: 20px;
      }
      & > a {
        display: block;
        width: 60px;
        height: 60px;
        background-size: 60px 120px;
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
        background-position: top left;
        @media (--desktop) {
          width: 50px;
          height: 50px;
          background-size: 50px 100px;
        }
      }
    }
    &_adways {
      background:url("../images/common/btn_adways_logo.png") no-repeat;
    }

    &_fb {
      background:url("../images/common/btn_sns_fb.png") no-repeat;
    }
    &_tw {
      background:url("../images/common/btn_sns_tw.png") no-repeat;
    }
  }
}

/* @keyframes bgroop {
  0% {
    transform: translate3d(0, 0, 0)
  }
  100% {
    transform: translate3d(100%, 0, 0)
  }
} */


@-webkit-keyframes bgroop {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 100% 0;
  }
  100% {
    background-position: 0 0;
  }
}
@keyframes bgroop {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 100% 0;
  }
  100% {
    background-position: 0 0;
  }
}

@keyframes loop {
  0% {background-position: 1168px 0;}
  25% {background-position: 876px 0;}
  50% {background-position: 584px 0;}
  75% {background-position: 292px 0;}
  100% {background-position: 0 0;}
  }
