.modal {
  width: 100%;
  height: 100%;
  position:fixed;
  top: 0;
  left: 0;
  background:#fffaa3;
  /* opacity: .9; */
  z-index: 100;
  transition: all .4s;
  transform: scale(0);
  opacity: 0;
  &.is-active {
    opacity: 1;
    transform: scale(1);
    z-index: 10000000;
  }
  &.newsDetailModal {
    .modal_content {
      background: #fff;
      width: 95%;
      padding: 50% 40%;
      @media (--tabletOnly) {
        padding: 42% 40%;
      }
      @media (--desktop) {
        width: 1020px;
        padding: 0;
        height: 660px;
      }
      &_body {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 85%;
        @media (--tabletMax) {
          width: 92%;
        }
        .movie_arrow  {
          position: absolute;
          cursor: pointer;
          top: 50%;
          margin-top: -32px;
          width: 40px;
          height: 40px;
          text-indent: -9999px;
          @media (--tabletMax) {
            /* bottom: 110%; */
            top: 124%;
          }
          &.prev {
            background: url("../images/news/modal_arrow.png") no-repeat bottom left;
            left: -57px;
            @media (--tabletMax) {
              left: 13px;
            }
            @media (--desktop) {
              &:hover {
                background-position: top left;
              }
            }
          }
          &.next {
            background: url("../images/news/modal_arrow.png") no-repeat bottom right;
            right: -57px;
            @media (--tabletMax) {
              right: 13px;
            }
            @media (--desktop) {
              &:hover {
                background-position: top right;
              }
            }
          }
        }
      }
      &_closeButton {
        @media (--tabletOnly) {
          width: 90px;
          height: 30px;
          right: 5%;
        }
        @media (--smartphone) {
          position: absolute;
          cursor: pointer;
          top: 4%;
          right: 4%;
          width: 60px;
          height: 20px;
        }
      }
    }
  }
  &_content {
    background-color:#fff;
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 512px;
    transform: translate(-50%, -50%);
    background:url("../images/common/miniOkamurachan_modal_bg_sp.png") no-repeat;
    background-size: contain;
    @media (--tabletOnly) {
      width: 67%;
    }
    @media (--desktop) {
      width: 700px;
      height: 500px;
      max-width: none;
      background:url("../images/common/miniOkamurachan_modal_bg.png") 0 0 / cover no-repeat;
    }
    &_closeButton {
      position: absolute;
      cursor: pointer;
      top: 10%;
      right: 10%;
      width: 60px;
      height: 20px;
      background: url(../images/common/modal_closeButton.png);
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 100000;
      @media (--tabletOnly) {
        width: 100px;
        height: 40px;
      }
      @media (--desktop) {
        top: 60px;
        right: 60px;
        width: 90px;
        height: 35px;
      }
    }
  }
}
