.thanksPage {
  .thanks {
    width: 100%;
    margin: 70px auto;
    text-align: center;
    @media (--desktop) {
      width: 400px;
    }
    &_charactor {
      margin: 30px 0 50px;
    }
    & > p {
      font-size: 16px;
      font-weight: bold;
      color: #525151;
      line-height: 1.7;
      text-align: center;
      @media (--desktop) {
        width: 400px;
        font-size: 19px;
      }
    }
    &_pageTop {
      display: flex;
      justify-content: center;
      align-items: center;
      & > a {
        display: block;
        text-decoration: none;
        color: #525151;
        @media (--desktop) {
          &:hover {
            text-decoration: underline;
          }
        }
      }
      &:before {
        width: 30px;
        height: 20px;
        margin-right: 10px;
        content:"";
        display: block;
        background: url("../images/common/link_icon.png") right 0 / contain no-repeat;
      }
    }
  }
}