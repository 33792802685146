.contactPage {
  .confirmform {
    opacity: 0;
    transition: opacity .3s;
    visibility: hidden;
    position: absolute;
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 50px;
    &.is-active {
      opacity: 1;
      visibility: visible;
    }
    &_attention {
      text-align: center;
      font-weight: bold;
      color: #525151;
    }
    table {
      width: 100%;
    }
    &_item {
      display: flex;
      padding: 30px;
      th {
        width: 200px;
        color: #525151;
        font-weight: bold;
      }
      td {
  
      }
    }
  }
  .contactForm {
    width: 100%;
    @media (--desktop) {
      width: 700px;
      margin: 50px auto 0;
    }
    .contact_table {
      width: 90%;
      margin: 0 auto;
      opacity: 1;
      transition: opacity .3s;
      &.is-disapear {
        opacity: 0;
        pointer-events: none;
      }
      @media (--desktop) {
        width: 100%;
      }
      tr {
        @media (--tabletMax) {
          width: 100%;
          display: block;
        }
        th {
          @media (--tabletMax) {
            width: 100%;
            display: block;
          }
          padding-top: 30px;
          font-weight: bold;
          width: 200px;
          color: #525151;
          text-align: left;
          vertical-align: top;
          .required {
            color: #ff093a;
          }
        }
        td {
          @media (--tabletMax) {
            width: 100%;
            display: block;
          }
          padding-top: 30px;
          .parsley-custom-error-message, .parsley-required, .parsley-equalto {
            color: #ff6363;
            margin-top: 5px;
          }
        }
      }
      input {
        width: 100%;
        padding: 5px;
        border: 1px solid #525151;
      }
      textarea {
        height: 160px;
        width: 100%;
        padding: 10px;
      }
    }
    .noteText {
      margin: 30px auto 0;
      line-height: 1.6;
      width: 90%;
      opacity: 1;
      transition: opacity .3s;
      &.is-active {
        opacity: 0;
      }
      @media (--desktop) {
        width: auto;
      }
      &_praivacy {
        margin-top: 10px;
        @media (--smartphone) {
          font-size: 11px;
          text-align: center;
        }
        & > a {
          color: #525151;
        }
      }
      &_praivacyComments {
        font-size: 11px;
        margin-bottom: 20px;
      }
      > p {
        margin: 20px 0 5px 0;
      }
    }
  }
  .entry_action {
    text-align: center;
    .parsley-required {
      color: #ff6363;
      margin-top: 5px;
    }
    .consent {
      opacity: 1;
      transition: opacity .3s;
      color: #525151;
      &.is-active {
        opacity: 0;
      }
    }
    .contact_commonBtn {
      margin-top: 40px;
      border: 2px solid #525151;
      padding: 20px;
      width: 70%;
      background: #fff;
      border-radius: 13px;
      font-weight: 700;
      font-size: 24px;
      box-shadow: 0 2px #525151;
      cursor: pointer;
      position: relative;
      color: #525151;
      @media (--tabletMax) {
        display: block;
        margin: 20px auto;
        position: relative;
        letter-spacing: 1.5px;
        width: 74%;
      }
      @media (--desktop) {
        width: 280px;
      }
    }
    .contact_SubmitBtn {
      @media (--desktop) {
        &:hover + .miniadchan {
          left: 2px;
          transform: rotate(360deg);
        }
        &:hover .miniadchan {
          left: 2px;
          transform: rotate(360deg);
        }
      }
    }
    .contact_ReturnBtn {
      transition: opacity .3s;
      margin-bottom: 60px;
      @media (--desktop) {
        margin-right: 60px;
        margin-bottom: 0;
      }
      &.is-active {
        opacity: 1;
        pointer-events: visible;
      }
    }
  }
  .contactBtn {
    position: relative;
    &.confirm {
      @media (--desktop) {
        display: flex;
        justify-content: center;
      }
      .contactBtn_decoration {
        top: -70px;
        @media (--desktop) {
          top: -10px;
        }
      }
    }
    &_back {
      /* display: none; */
    }
    &_decoration {
      width: 50px;
      height: 40px;
      position: relative;
      display: inline-block;
      z-index: -1;
      transition: all .3s ease-in;
      &.miniadchan {
        top: -90px;
        left: 39%;
        transform: rotate(376deg);
        @media (--desktop) {
          top: -30px;
          left: -20px;
        }
      }
      &.minimurachan {
        top: 5%;
        left: 5%;
        position: absolute;
        transform: rotate(356deg);
        @media (--desktop) {
          top: -30px;
          left: -368px;
          position: relative;
        }
      }
    }
  }
}

.ua-ie {
  .contactPage .miniadchan.contactBtn_decoration {
    top: 20px;
  }
}

input[type="button"], input[type="text"], input[type="tel"], input[type="submit"], input[type="email"], textarea {
  -webkit-appearance: none;
  border-radius: 0;
}
